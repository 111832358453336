import React from 'react';

const Footer = () => (
  <footer className="mt-8 py-6 bg-green-800 text-white">
    <div className="container mx-auto flex flex-col md:flex-row items-center justify-between px-4">
      <div className="flex items-center mb-4 md:mb-0">
        <a 
          href="https://crocus-urban.org/" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          <img 
            src="https://i.ibb.co/CbK92Jm/crocus-dark-1.png" 
            alt="CROCUS Logo" 
            className="h-12 mr-4"
          />
        </a>
        <p className="text-sm">
          Powered by the Community Research on Climate and Urban Science (CROCUS) Project
        </p>
      </div>
      <div className="text-sm">
        <p>© 2024 CROCUS. All rights reserved.</p>
        <p>
          Learn more at{' '}
          <a 
            href="https://crocus-urban.org/" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="underline hover:text-green-200"
          >
            crocus-urban.org
          </a>
        </p>
      </div>
    </div>
  </footer>
);

export default Footer;
