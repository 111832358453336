import React from 'react';

const SensorLocations = ({ sensors, onSensorSelect, selectedSensor }) => (
  <div className="mb-6">
    <h2 className="text-2xl font-semibold mb-3 text-green-700">Available Sensors</h2>
    <div className="grid grid-cols-2 sm:grid-cols-3 gap-3">
      {sensors.map((sensor) => (
        <button
          key={sensor.id}
          onClick={() => onSensorSelect(sensor)}
          className={`p-3 border rounded-lg transition-colors duration-200 ${
            selectedSensor?.id === sensor.id
              ? 'bg-green-100 border-green-500 text-green-700'
              : 'bg-white hover:bg-green-50 border-gray-300 text-gray-700 hover:text-green-600'
          }`}
        >
          {sensor.id} - {sensor.species}
        </button>
      ))}
    </div>
  </div>
);

export default SensorLocations;