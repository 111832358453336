import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Header from './components/Header';
import SensorMap from './components/SensorMap';
import SensorLocations from './components/SensorLocations';
import SensorDetails from './components/SensorDetails';
import DataTable from './components/DataTable';
import SensorVisualization from './components/SensorVisualization';
import Footer from './components/Footer';

const App = () => {
  const { sensorId } = useParams();
  const navigate = useNavigate();
  const [selectedSensor, setSelectedSensor] = useState(null);
  const [showDetailedData, setShowDetailedData] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortColumn, setSortColumn] = useState('timestamp');
  const [sortDirection, setSortDirection] = useState('desc');
  const [timeRange, setTimeRange] = useState('hour');
  const [sensorData, setSensorData] = useState([]);
  const [lastReadings, setLastReadings] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [mostRecentReading, setMostRecentReading] = useState(null);

  // Sensor data
  const sensors = useMemo(() => [
    { id: "SX61NA0D", coordinates: [41.71929, -87.61260], interval: 15, site: "CSU", species: "Cottonwood", imageUrl: "/images/SX61NA0D.jpg", diameter: "43.1 inches" },
    { id: "SX61NA0W", coordinates: [41.71951, -87.61275], interval: 15, site: "CSU", species: "Cottonwood", imageUrl: "/images/SX61NA0W.jpg", diameter: "33.1 inches" },
    { id: "SX61NA0E", coordinates: [41.71935, -87.61331], interval: 10, site: "CSU", species: "Cottonwood", imageUrl: "/images/SX61NA0E.jpg", diameter: "47.8 inches" },
    { id: "SX61NA0P", coordinates: [41.71899, -87.61238], interval: 15, site: "CSU", species: "American Elm", imageUrl: "/images/SX61NA0P.jpg", diameter: "32.5 inches" },
    { id: "SX61NA0H", coordinates: [41.71909, -87.61168], interval: 15, site: "CSU", species: "American Elm", imageUrl: "/images/SX61NA0H.jpg", diameter: "64.8 inches" },
    { id: "SX61NA08", coordinates: [41.72003, -87.61384], interval: 15, site: "CSU", species: "Maple", imageUrl: "/images/SX61NA08.jpg", diameter: "34.25 inches" },
    { id: "SX61NA0T", coordinates: [41.71988, -87.61388], interval: 15, site: "CSU", species: "Maple", imageUrl: "/images/SX61NA0T.jpg", diameter: "38 inches" },
    { id: "SX61NA0A", coordinates: [41.72046, -87.61387], interval: 15, site: "CSU", species: "Maple", imageUrl: "/images/SX61NA0A.jpg", diameter: "22.25 inches" },
  ], []);

  const fetchLatestReadingsForAllSensors = useCallback(async () => {
    const now = new Date();
    const startTime = new Date(now.getTime() - 60 * 60 * 1000); // 1 hour ago

    try {
      const response = await axios.post('/api/proxy', {
        start: startTime.toISOString(),
        end: now.toISOString(),
        filter: {
          plugin: "registry.sagecontinuum.org/flozano/lorawan-listener:*.*",
          vsn: "W08E",
          name: "uncorrected_inner|uncorrected_outer|battery_voltage"
        }
      });

      if (typeof response.data === 'string') {
        const dataArray = response.data.split('\n')
          .filter(line => line.trim() !== '')
          .map(line => JSON.parse(line));

        const latestReadings = {};
        sensors.forEach(sensor => {
          const sensorData = dataArray.filter(item => 
            item.meta['Serial Number_tag'] === sensor.id || item.meta['serial_number_tag'] === sensor.id
          );
          if (sensorData.length > 0) {
            const latestReading = sensorData.reduce((latest, current) => {
              return new Date(current.timestamp) > new Date(latest.timestamp) ? current : latest;
            });

            latestReadings[sensor.id] = {
              timestamp: latestReading.timestamp,
              Date: new Date(latestReading.timestamp).toISOString().split('T')[0],
              Time: new Date(latestReading.timestamp).toTimeString().split(' ')[0],
              [latestReading.name]: latestReading.value
            };
          }
        });

        setLastReadings(latestReadings);
      }
    } catch (error) {
      console.error('Error fetching latest readings for all sensors:', error);
    }
  }, [sensors]);

  // Fetch latest readings for all sensors on load
  useEffect(() => {
    fetchLatestReadingsForAllSensors();
  }, [fetchLatestReadingsForAllSensors]);

  // Set initial selected sensor based on URL
  useEffect(() => {
    if (sensorId) {
      const sensor = sensors.find(s => s.id === sensorId);
      if (sensor) {
        setSelectedSensor(sensor);
      }
    }
  }, [sensorId, sensors]);

  // Function to fetch sensor data
  const fetchSensorData = useCallback(async () => {
    if (!selectedSensor) return;

    setIsLoading(true);
    setErrorMessage('');
    const now = new Date();
    let start = new Date(now);

    switch (timeRange) {
      case 'hour':
        start.setHours(now.getHours() - 1);
        break;
      case 'day':
        start.setDate(now.getDate() - 1);
        break;
      case '3days':
        start.setDate(now.getDate() - 3);
        break;
      default:
        start.setHours(now.getHours() - 1);
    }

    const startStr = start.toISOString();
    const endStr = now.toISOString();

    try {
      const response = await axios.post('/api/proxy', {
        start: startStr,
        end: endStr,
        filter: {
          plugin: "registry.sagecontinuum.org/flozano/lorawan-listener:*.*",
          vsn: "W08E",
          name: "uncorrected_inner|uncorrected_outer|battery_voltage"
        }
      });

      if (typeof response.data === 'string') {
        const dataArray = response.data.split('\n')
          .filter(line => line.trim() !== '')
          .map(line => JSON.parse(line));

        const parsedData = dataArray
          .filter(item => 
            item.meta['Serial Number_tag'] === selectedSensor.id || item.meta['serial_number_tag'] === selectedSensor.id
          )
          .reduce((acc, item) => {
            const timestamp = new Date(item.timestamp);
            const date = timestamp.toISOString().split('T')[0];
            const time = timestamp.toTimeString().split(' ')[0];
            
            if (!acc[`${date} ${time}`]) {
              acc[`${date} ${time}`] = { Date: date, Time: time, timestamp: timestamp };
            }
            
            acc[`${date} ${time}`][item.name] = item.value;
            return acc;
          }, {});

        const finalData = Object.values(parsedData).sort((a, b) => b.timestamp - a.timestamp);
        setSensorData(finalData);

        // Set the most recent reading
        if (finalData.length > 0) {
          setMostRecentReading(finalData[0]);
        }
      } else {
        console.error('Unexpected response format:', response.data);
        throw new Error('Unexpected response format from server');
      }
    } catch (error) {
      console.error('Error fetching sensor data:', error);
      setSensorData([]);
      setErrorMessage(error.response?.data?.error || error.message || 'Failed to fetch sensor data. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, [selectedSensor, timeRange]);

  useEffect(() => {
    fetchSensorData();
  }, [fetchSensorData]);

  const handleSensorSelect = useCallback((sensor) => {
    console.log("Selecting sensor:", sensor);
    setSelectedSensor(sensor);
    setShowDetailedData(false);
    setCurrentPage(1);
    navigate(`/csu/${sensor.id}`);
  }, [navigate]);

  const handleToggleDetailedData = useCallback(() => {
    setShowDetailedData(prev => !prev);
  }, []);

  const handleRowsPerPageChange = useCallback((event) => {
    setRowsPerPage(parseInt(event.target.value));
    setCurrentPage(1);
  }, []);

  const handleTimeRangeChange = useCallback((event) => {
    setTimeRange(event.target.value);
    setCurrentPage(1);
  }, []);

  const handleSort = useCallback((column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('desc');
    }
  }, [sortColumn, sortDirection]);

  const handlePageChange = useCallback((newPage) => {
    setCurrentPage(newPage);
  }, []);

  const sortedData = useMemo(() => {
    return [...sensorData].sort((a, b) => {
      if (a[sortColumn] < b[sortColumn]) return sortDirection === 'asc' ? -1 : 1;
      if (a[sortColumn] > b[sortColumn]) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  }, [sensorData, sortColumn, sortDirection]);

  const totalPages = Math.ceil(sortedData.length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const currentData = sortedData.slice(startIndex, endIndex);

  const exportToCSV = useCallback(() => {
    if (!selectedSensor || sortedData.length === 0) return;

    const headers = ['Date', 'Time', 'Uncorrected Inner', 'Uncorrected Outer', 'Battery Voltage'];
    const csvContent = [
      headers.join(','),
      ...sortedData.map(row => 
        `${row.Date},${row.Time},${row.uncorrected_inner || ''},${row.uncorrected_outer || ''},${row.battery_voltage ? row.battery_voltage + ' V' : ''}`
      )
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `sensor_data_${selectedSensor.id}.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [selectedSensor, sortedData]);

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow max-w-6xl mx-auto p-6 bg-gray-50 rounded-lg shadow-lg">
        <Header />
        <SensorMap 
          sensors={sensors} 
          onSensorClick={handleSensorSelect} 
          selectedSensor={selectedSensor?.id}
          lastReadings={lastReadings}
        />
        <SensorLocations 
          sensors={sensors} 
          onSensorSelect={handleSensorSelect} 
          selectedSensor={selectedSensor} 
        />
        {selectedSensor && (
          <SensorDetails 
            sensor={selectedSensor}
            mostRecentData={mostRecentReading || lastReadings[selectedSensor.id]}
            onToggleDetailedData={handleToggleDetailedData}
            showDetailedData={showDetailedData}
          />
        )}
        {selectedSensor && showDetailedData && (
          <>
            {isLoading ? (
              <p className="text-center text-gray-600">Loading data...</p>
            ) : errorMessage ? (
              <p className="text-center text-red-500">{errorMessage}</p>
            ) : (
              <>
                <DataTable 
                  currentData={currentData}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  timeRange={timeRange}
                  onTimeRangeChange={handleTimeRangeChange}
                  onExportCSV={exportToCSV}
                  onSort={handleSort}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
                <SensorVisualization data={sortedData} />
              </>
            )}
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default App;
