import React, { useEffect, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

function MapController({ selectedSensorCoords }) {
  const map = useMap();
  
  useEffect(() => {
    if (selectedSensorCoords) {
      map.setView(selectedSensorCoords, 18);
    }
  }, [selectedSensorCoords, map]);
  return null;
}

const SensorMap = ({ sensors, onSensorClick, selectedSensor, lastReadings, fetchLatestReading }) => {
  const mapRef = useRef(null);
  const selectedSensorCoords = sensors.find(s => s.id === selectedSensor)?.coordinates;

  const handleFocusSensor = (coords) => {
    if (mapRef.current) {
      mapRef.current.setView(coords, 18);
    }
  };

  return (
    <div className="mb-6">
      <h2 className="text-2xl font-semibold mb-3 text-green-700">Sensor Map</h2>
      <MapContainer 
        center={[41.7198, -87.6128]} 
        zoom={17} 
        style={{ height: "400px" }}
        ref={mapRef}
      >
        <TileLayer
          url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
          attribution='&copy; <a href="https://www.esri.com/">Esri</a>'
        />
        {sensors.map((sensor) => {
          const lastReading = lastReadings[sensor.id];
          return (
            <Marker key={sensor.id} position={sensor.coordinates}>
              <Popup>
                <div className="text-center">
                  <h3 className="text-lg font-semibold">{sensor.id}</h3>
                  <p className="text-sm">Species: {sensor.species}</p>
                  <p className="text-sm">
                    Last reading: {lastReading ? `${lastReading.Date} ${lastReading.Time}` : 'Loading...'}
                  </p>
                  <button 
                    onClick={() => {
                      onSensorClick(sensor);
                      handleFocusSensor(sensor.coordinates);
                      fetchLatestReading(sensor.id);
                    }}
                    className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded text-xs"
                  >
                    View Data
                  </button>
                </div>
              </Popup>
            </Marker>
          );
        })}
        <MapController selectedSensorCoords={selectedSensorCoords} />
      </MapContainer>
    </div>
  );
};

export default SensorMap;