import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import App from './App';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/csu" element={<App />} />
        <Route path="/csu/:sensorId" element={<App />} />
        <Route path="*" element={<Navigate to="/csu" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;