import React from 'react';
import { Info } from 'lucide-react';

const SensorDetails = ({ sensor, mostRecentData, onToggleDetailedData, showDetailedData }) => {
  if (!sensor) return null;
  
  const getTimeDifference = () => {
    if (!mostRecentData || !mostRecentData.timestamp) return 'N/A';
    const now = new Date();
    const dataTime = new Date(mostRecentData.timestamp);
    const diffInMinutes = Math.floor((now - dataTime) / (1000 * 60));
    return diffInMinutes;
  };
  
  const timeDiff = getTimeDifference();
  
  return (
    <div className="mb-6 p-4 bg-white rounded-lg shadow">
      <h3 className="text-xl font-semibold mb-2 text-green-700">Selected Sensor: {sensor.id}</h3>
      <p className="text-gray-700">Tree Species: {sensor.species || 'N/A'}</p>
      <p className="text-gray-700">Tree Diameter: {sensor.diameter || 'N/A'}</p>
      <p className="text-gray-700">Site: {sensor.site || 'N/A'}</p>
      <p className="text-gray-700">Coordinates: {sensor.coordinates ? sensor.coordinates.join(', ') : 'N/A'}</p>
      <p className="text-gray-700">Interval: {sensor.interval ? `${sensor.interval} minutes` : 'N/A'}</p>
      {mostRecentData ? (
        <>
          <p className="text-gray-700 mt-2">
            The most recent tree health data for this tree was received <strong>{timeDiff} minutes</strong> ago with the{' '}
            <u>uncorrected inner</u> value being <strong>{mostRecentData.uncorrected_inner?.toFixed(2) || 'N/A'} cm/hr</strong>,{' '}
            <u>uncorrected outer</u> value being <strong>{mostRecentData.uncorrected_outer?.toFixed(2) || 'N/A'} cm/hr</strong>,{' '}
            and <u>battery voltage</u> <strong>{mostRecentData.battery_voltage?.toFixed(2) || 'N/A'} V</strong>.
          </p>
          <div className="mt-2 text-sm text-gray-400 flex items-start">
            <Info className="mr-2 flex-shrink-0 text-gray-400" size={16} />
            <p>
              <strong>Note:</strong> "Uncorrected inner" and "uncorrected outer" values show how much water is moving through different parts of the tree.
            </p>
          </div>
        </>
      ) : (
        <p className="text-gray-700 mt-2">No recent data available for this sensor.</p>
      )}
      <div className="mt-4">
        <img src={sensor.imageUrl} alt={`Sensor ${sensor.id}`} className="w-full max-w-[200px] h-auto object-cover rounded mb-2" />
        <button
          onClick={onToggleDetailedData}
          className="w-full px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors duration-200"
        >
          {showDetailedData ? "Hide Detailed Data" : "Show Detailed Data"}
        </button>
      </div>
    </div>
  );
};

export default SensorDetails;