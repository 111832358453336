import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const SensorVisualization = ({ data }) => {
  const formatXAxis = (tickItem) => {
    const date = new Date(tickItem);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  // Sort the data from oldest to newest
  const sortedData = [...data].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

  return (
    <div className="mt-6">
      <h3 className="text-xl font-semibold mb-3 text-green-700">Sensor Data Visualization</h3>
      <ResponsiveContainer width="100%" height={500}>
        <LineChart
          data={sortedData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 120,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            dataKey="timestamp" 
            tickFormatter={formatXAxis} 
            interval={Math.ceil(sortedData.length / 10)} 
            angle={-45} 
            textAnchor="end" 
            height={80}
            tick={{fontSize: 10}}
          />
          <YAxis />
          <Tooltip labelFormatter={formatXAxis} />
          <Legend verticalAlign="bottom" height={36} wrapperStyle={{paddingTop: "20px"}}/>
          <Line type="monotone" dataKey="uncorrected_inner" stroke="#8884d8" activeDot={{ r: 8 }} name="Uncorrected Inner (cm/hr)" />
          <Line type="monotone" dataKey="uncorrected_outer" stroke="#82ca9d" activeDot={{ r: 8 }} name="Uncorrected Outer (cm/hr)" />
          <Line type="monotone" dataKey="battery_voltage" stroke="#ffc658" name="Battery Voltage (V)" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SensorVisualization;
