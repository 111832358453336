import React from 'react';
import { Download, ArrowUpDown, ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from 'lucide-react';

const DataTable = ({ 
  currentData, 
  rowsPerPage, 
  onRowsPerPageChange, 
  timeRange, 
  onTimeRangeChange, 
  onExportCSV, 
  onSort,
  currentPage,
  totalPages,
  onPageChange
}) => {
  console.log("Current data in DataTable:", currentData); // Debug log

  const columns = [
    { header: 'Date', key: 'Date' },
    { header: 'Time', key: 'Time' },
    { header: 'Uncorrected Inner', key: 'uncorrected_inner' },
    { header: 'Uncorrected Outer', key: 'uncorrected_outer' },
    { header: 'Battery Voltage', key: 'battery_voltage' }
  ];

  return (
    <div>
      <div className="flex justify-between items-center mb-3">
        <select
          value={timeRange}
          onChange={onTimeRangeChange}
          className="border rounded p-2 bg-white text-gray-700"
        >
          <option value="hour">Past Hour</option>
          <option value="day">Past Day</option>
          <option value="3days">Past 3 Days</option>
        </select>
        <button
          onClick={onExportCSV}
          className="flex items-center justify-center px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-200"
          title="Download as CSV"
        >
          <Download size={20} />
        </button>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full border-collapse border border-gray-300 bg-white">
          <thead>
            <tr className="bg-green-100">
              {columns.map((column) => (
                <th
                  key={column.key}
                  className="border border-gray-300 p-2 text-left cursor-pointer hover:bg-green-200"
                  onClick={() => onSort(column.key)}
                >
                  <div className="flex items-center">
                    {column.header}
                    <ArrowUpDown size={16} className="ml-1" />
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentData.map((row, index) => (
              <tr key={index} className="hover:bg-gray-50">
                <td className="border border-gray-300 p-2">{row.Date || 'N/A'}</td>
                <td className="border border-gray-300 p-2">{row.Time || 'N/A'}</td>
                <td className="border border-gray-300 p-2">
                  {typeof row.uncorrected_inner === 'number' ? `${row.uncorrected_inner.toFixed(3)} cm/hr` : 'N/A'}
                </td>
                <td className="border border-gray-300 p-2">
                  {typeof row.uncorrected_outer === 'number' ? `${row.uncorrected_outer.toFixed(3)} cm/hr` : 'N/A'}
                </td>
                <td className="border border-gray-300 p-2">
                  {typeof row.battery_voltage === 'number' ? `${row.battery_voltage.toFixed(2)} V` : 'N/A'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-between items-center mt-4">
        <div className="flex items-center space-x-2">
          <span className="text-gray-700">Page {currentPage} of {totalPages}</span>
          <button
            onClick={() => onPageChange(1)}
            disabled={currentPage === 1}
            className="p-2 border rounded disabled:opacity-50 bg-white text-green-700 hover:bg-green-50"
          >
            <ChevronsLeft size={20} />
          </button>
          <button
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="p-2 border rounded disabled:opacity-50 bg-white text-green-700 hover:bg-green-50"
          >
            <ChevronLeft size={20} />
          </button>
          <button
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="p-2 border rounded disabled:opacity-50 bg-white text-green-700 hover:bg-green-50"
          >
            <ChevronRight size={20} />
          </button>
          <button
            onClick={() => onPageChange(totalPages)}
            disabled={currentPage === totalPages}
            className="p-2 border rounded disabled:opacity-50 bg-white text-green-700 hover:bg-green-50"
          >
            <ChevronsRight size={20} />
          </button>
        </div>
        <div className="flex items-center space-x-2">
          <span className="text-gray-700">Rows per page:</span>
          <select
            value={rowsPerPage}
            onChange={onRowsPerPageChange}
            className="border rounded p-2 bg-white text-gray-700"
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default DataTable;
