import React from 'react';

const Header = () => (
  <div className="flex justify-between items-start mb-6">
    <div className="flex-grow pr-4">
      <h1 className="text-3xl font-bold mb-2 text-green-800">Tree Health Dashboard</h1>
      <p className="text-gray-600">
        This dashboard visualizes data from sap flow sensors located at Chicago State University. 
        These sensors measure the flow of sap in plants and provide valuable insights into plant health and water usage.
      </p>
    </div>
    <div className="flex-shrink-0">
      <a 
        href="https://ictinternational.com/knowledge-base/tree-and-plant-water-use/" 
        target="_blank" 
        rel="noopener noreferrer"
      >
        <img 
          src="https://ictinternational.com/wp-content/uploads/2023/10/Sapflow-Gif.gif" 
          alt="Sap Flow Sensor" 
          className="w-40 h-auto object-contain rounded-lg"
        />
      </a>
    </div>
  </div>
);

export default Header;
